$(document).ready(function () {
    const $htmlBody = $('html, body');
    const $html = $('html');
    const $body = $('body');
    const $pageTop = $('.page--top');
    const $arrow = '<svg class="picto-arrow" xmlns="http://www.w3.org/2000/svg" width="48.215" height="94.431" viewBox="0 0 48.215 94.431"><g transform="translate(1 1.414)"><path data-name="Tracé 267" d="M317.609,432.783l-45.8-45.8,45.8-45.8" transform="translate(-271.808 -341.181)" fill="none" stroke="#161615" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/></g></svg>';
    const $arrowNav = '<svg class="picto-arrow-nav" xmlns="http://www.w3.org/2000/svg" width="13.503" height="23.619" viewBox="0 0 13.503 23.619"><path data-name="Icon ionic-ios-arrow-back" class="cls-1" d="M15.321,18l8.937-8.93a1.688,1.688,0,0,0-2.391-2.384L11.742,16.8a1.685,1.685,0,0,0-.049,2.327L21.86,29.32a1.688,1.688,0,0,0,2.391-2.384Z" transform="translate(-11.251 -6.194)"/></svg>';

    const appHeight = () => {
        const vh = window.innerHeight * 0.01;
        const bannerHeight = $('.header .st_banner_row').innerHeight() / 2;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--banner-height', `${bannerHeight}px`);
    }
    window.addEventListener('resize', appHeight);
    appHeight();

    if ($pageTop.length) {
        scrollTo($pageTop, $html, 0, '+', 1000);
    }

    const $header = $('.header');
    if ($header.length) {
        let scrollTopHeaderFunction = function () {
            let scrollTop = $(window).scrollTop();
            if (scrollTop > 10) {
                $body.addClass('scrolled');
            } else {
                $body.removeClass('scrolled');
            }
        };
        window.addEventListener('scroll', scrollTopHeaderFunction, false);
        scrollTopHeaderFunction();
    }

    const $headerCta = $('.header--aside--cta')
    if ($headerCta.length) {
        $(window).resize(function(){
            if (window.matchMedia("(min-width: 768px)").matches) {
                checkSessionStorageHeaderCta($headerCta);
            }
        }).resize();
    }

    function checkSessionStorageHeaderCta($headerCta) {
        if (typeof sessionStorage != 'undefined') {
            let $sessionCta = sessionStorage.getItem('cta');
            if ($sessionCta === null) {
                setTimeout(function(){
                    $('.cta--link', $headerCta).addClass('cta--link--close');
                    sessionStorage.setItem('cta', 'close');
                }, 5000);
            } else if ($sessionCta == 'close') {
                $('.cta--link', $headerCta).addClass('cta--link--close');
            }
        }
    }

    $('.dotdotdot').dotdotdot();

    const $menu = $('.adtm_menu_container');
    if ($menu.length) {
        const $openMenuMobile = $('.advtm_menu_toggle');
        if ($openMenuMobile.length) {
            $openMenuMobile.on('click', function () {
                $body.addClass('menu-open');
                $menu.addClass('open');
            })
        }

        const $closeMenuMobile = $('.adtm_menu_close');
        if ($closeMenuMobile.length) {
            $closeMenuMobile.on('click', function () {
                $body.removeClass('menu-open');
                $menu.removeClass('open');
            });
        }
    }

    const $linkObf = $('.link--obf[data-href!=""]');
    if ($linkObf.length) {
        $(document).on('click', '.link--obf[data-href!=""]', function() {
            if ($(this).data('target') == '_blank') {
                window.open(atob($(this).data('href')), '_blank');
            }
            else {
                window.location.href = atob($(this).data('href'));
            }
        });

        $(document).on('middleclick', '.link--obf[data-href!=""]', function(){
            window.open(atob($(this).data('href')), '_blank');
        });


    }

    const $btnCommentRead = $('.product--content--col--right .product--comment');
    if ($btnCommentRead.length) {
        const $delta = $('header').innerHeight() + 20;
        const $target = $('.product--reviews');
        scrollTo($btnCommentRead, $target, $delta, '-', 1000);
    }

    const $productVideoCta = $('.product--images--cta');
    if ($productVideoCta.length) {
        $('.cta--link', $productVideoCta).fancybox({
            type: "iframe",
        });
        // scrollTo($productVideoCta, $('.product--details'), $header.innerHeight(), '-', 1000);
    }

    const $accessoriesProduct = $('.product--accessories');
    if ($accessoriesProduct.length) {
        const $accessoriesProductSlider = $('.product--accessories--products', $accessoriesProduct);
        $accessoriesProductSlider.slick({
            arrows: true,
            appendArrows: '.product--accessories--arrows',
            centerMode: false,
            centerPadding: '0',
            cssEase: 'ease-in-out',
            dots: true,
            dotsClass: 'product--accessories--dots',
            edgeFriction: 0,
            infinite: true,
            nextArrow: '<div class="product--accessories--arrow product--accessories--arrow--next">' + $arrow + '</div>',
            prevArrow: '<div class="product--accessories--arrow product--accessories--arrow--prev">' + $arrow + '</div>',
            slidesToScroll: 3,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1
                    }
                }
            ]
        });
    }

    const $featuredProducts = $('.featured-products');
    if ($featuredProducts.length) {
        const $featuredProductsSlider = $('.featured-products--products', $featuredProducts);
        $featuredProductsSlider.slick({
            arrows: true,
            appendArrows: '.featured-products--arrows',
            centerMode: false,
            centerPadding: '0',
            cssEase: 'ease-in-out',
            dots: true,
            dotsClass: 'featured-products--dots',
            edgeFriction: 0,
            infinite: true,
            nextArrow: '<div class="featured-products--arrow featured-products--arrow--next">' + $arrow + '</div>',
            prevArrow: '<div class="featured-products--arrow featured-products--arrow--prev">' + $arrow + '</div>',
            slidesToScroll: 3,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ]
        });
    }

    const $pushIntro = $('.push--intro--items');
    if ($pushIntro.length) {
        $pushIntro.slick({
            arrows: true,
            appendArrows: '.push--intro--arrows',
            autoplay: true,
            autoplaySpeed: 3000,
            centerMode: true,
            centerPadding: '0',
            cssEase: 'ease-in-out',
            dots: false,
            edgeFriction: 0,
            fade: true,
            infinite: true,
            nextArrow: '<div class="push--intro--arrow push--intro--arrow--next">' + $arrow + '</div>',
            prevArrow: '<div class="push--intro--arrow push--intro--arrow--prev">' + $arrow + '</div>',
            slidesToScroll: 1,
            slidesToShow: 1
        });
        const $pushHeight = $pushIntro.height();
        if (window.matchMedia("(max-width: 767px)").matches) {
            const $currentSlide = $pushIntro.slick('slickCurrentSlide');
            const $slide = $('.push--intro--item[data-slick-index="' + $currentSlide + '"]');
            const $height = $slide.find('.push--intro--item--image').height();
            $slide.find('.push--intro--item--content').css('height', `calc(${$pushHeight}px - ${$height}px)`);
        }
        $pushIntro.on('afterChange', function (slick, currentSlide) {
            if (window.matchMedia("(max-width: 767px)").matches) {
                const $id = currentSlide.currentSlide;
                const $slide = $('.push--intro--item[data-slick-index="' + $id + '"]');
                const $height = $slide.find('.push--intro--item--image').height();
                $slide.find('.push--intro--item--content').css('height', `calc(${$pushHeight}px - ${$height}px)`);
            }
        });
        // $(window).resize(function(){
        //     if (window.matchMedia("(max-width: 767px)").matches) {
        //         const $heightPush = $pushIntro.height();
        //         console.log($heightPush);
        //         $('.push--intro--item').each(function () {
        //             const $el = $(this);
        //             let $heightImage = $el.find('.push--intro--item--image').height();
        //             console.log($heightImage);
        //             let $height = $heightPush - $heightImage;
        //             console.log($height);
        //             $el.find('.push--intro--item--content').css('height', `${$height}px`);
        //         });
        //     }
        // }).resize();
    }

    const $pushLinks = $('.push--links');
    if ($pushLinks.length) {
        const $pushLinksSlider = $('.push--links--items', $pushLinks);
        const $pushLinksNav = $('.push--links--nav', $pushLinks);
        // $('.push--links--nav--item', $pushLinksNav).on('click', function () {
        //     $('.push--links--nav--item', $pushLinks).removeClass('active');
        //     $(this).addClass('active');
        //     const $index = $(this).attr('data-slick-index');
        //     $pushLinksSlider.slick('slickGoTo', $index);
        // });

        $pushLinksSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            $('.push--links--nav--item', $pushLinks).removeClass('active');
            $('.push--links--nav--item[data-slick-index="' + nextSlide + '"]', $pushLinks).addClass('active');
        });

        $pushLinksSlider.slick({
            arrows: true,
            appendArrows: '.push--links--arrows',
            centerMode: true,
            centerPadding: '0',
            cssEase: 'ease-in-out',
            dots: false,
            edgeFriction: 0,
            fade: true,
            infinite: true,
            nextArrow: '<div class="push--links--arrow push--links--arrow--next">' + $arrow + '</div>',
            prevArrow: '<div class="push--links--arrow push--links--arrow--prev">' + $arrow + '</div>',
            slidesToScroll: 1,
            slidesToShow: 1
        });
    }

    const $filters = $('.products-list--filters');
    if ($filters.length) {
        if (!$filters.find('.PM_ASBlockOutput').length) {
            $filters.addClass('products-list--filters--hidden');
        }
    }

    const $categoryFAQ = $('.category--footer--faq--items');
    if ($categoryFAQ.length) {
        const $categoryFAQItem = $('.category--footer--faq--item', $categoryFAQ);
        const $svg = '<svg class="picto-more-less" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><g data-name="Groupe 18540" transform="translate(-1454.5 -4252.5)"><line data-name="Ligne 7" class="cls-1 line-less" x2="18" transform="translate(1455.5 4262.5)"/><line data-name="Ligne 8" class="cls-1 line-more" y2="18" transform="translate(1464.5 4253.5)"/></g></svg>';
        $categoryFAQItem.each(function () {
            $(this).find('.category--footer--faq--item--picto').html($svg);
        });
        $categoryFAQItem.on('click', function () {
            $(this).toggleClass('category--footer--faq--item--active');
        });
    }

    const $productReviews = $('.product--reviews--items');
    if ($productReviews.length) {
        $('.pagination--page, .pagination--previous, .pagination--next').on('click', function () {
            const $pagination = $('.pagination--inner');
            const $currentPage = $pagination.attr('data-current-page');
            const $nextPage = $(this).attr('data-page');
            const $idProduct = $pagination.attr('data-id-product');
            const $nb = $pagination.attr('data-nb');
            $.ajax({
                type: "POST",
                url: "module/willowproductcomments/ajax",
                async: true,
                dataType: "json",
                data: {
                    'currentPage': $currentPage,
                    'nextPage': $nextPage,
                    'idProduct': $idProduct,
                    'nb': $nb,
                },
                success: function ($response) {
                    const $reviews = $('.product--reviews--items');
                    $reviews.empty();
                    $reviews.html($response.html);
                    $('.pagination--page').removeClass('active current');
                    $('.pagination--page[data-page="' + $response.pagination.currentPage + '"]').addClass('active current');
                    $('.pagination--previous').attr('data-page', $response.pagination.previousPage);
                    if ($response.pagination.previousPage === $response.pagination.currentPage) {
                        $('.pagination--previous > div').addClass('pagination--nolink').removeClass('pagination--link');
                    } else {
                        $('.pagination--previous > div').addClass('pagination--link').removeClass('pagination--nolink');
                    }
                    $('.pagination--next').attr('data-page', $response.pagination.nextPage);
                    if ($response.pagination.nextPage === $response.pagination.currentPage) {
                        $('.pagination--next > div').addClass('pagination--nolink').removeClass('pagination--link');
                    } else {
                        $('.pagination--next > div').addClass('pagination--link').removeClass('pagination--nolink');
                    }
                    $('.pagination--inner').attr('data-current-page', $response.pagination.currentPage);
                },
                complete: function () {
                    $htmlBody.animate({
                        scrollTop: $('.product--reviews--inner').offset().top - 200
                    }, 500);
                }
            });
        });
    }

    const $ctaCrisp = $('.cta--ask-question');
    if ($ctaCrisp.length) {
        CRISP_WEBSITE_ID = "810de2d1-b3ac-4107-bc60-813e8e4f27a9";
        (function () {
            d = document;
            s = d.createElement("script");
            s.src = "https://client.crisp.im/l.js";
            s.async = 1;
            d.getElementsByTagName("head")[0].appendChild(s);
        })();
        $('.cta--link', $ctaCrisp).on('click', function () {
            $('.crisp-client #crisp-chatbox').addClass('show');
            const $crispButton = $('.crisp-client a[role="button"]');
            if ($crispButton.length) {
                if ($crispButton.length == 1) {
                    $crispButton[0].click();
                } else {
                    $crispButton[($crispButton.length - 1)].click();
                }
            }
        });
    }

    if ($('body.product').length) {
        $(window).resize(function(){
            // Mise en place de la barre de rappel  du produit (nom, prix, photo)
            if (window.matchMedia("(max-width: 1023px)").matches) {
                const $productResumeImage = $('.product--resume--image');
                const $productResumeSticky = $('.product--resume--sticky');
                if ($productResumeImage.length) {
                    $productResumeImage.appendTo($productResumeSticky);
                }

                let scrollTopProductFunction = function () {
                    let scrollTop = $(window).scrollTop();
                    const $productRightColumn = $('.product--content--col--right');
                    if (scrollTop > $productRightColumn.offset().top) {
                        $productResumeSticky.addClass('product--resume--sticky--show');
                    } else {
                        $productResumeSticky.removeClass('product--resume--sticky--show');
                    }
                };
                window.addEventListener('scroll', scrollTopProductFunction, false);
                scrollTopProductFunction();
            }
        }).resize();

        const $printProduct = $('.product--actions--print');
        if ($printProduct.length) {
            $printProduct.on('click', function() {
                window.print();
            });
        }

        const $configurator = $('.product--willow-pack');
        if ($configurator.length) {
            $('.product--configurator--action--print').on('click', function () {
                window.print();
            });
            const $itemConfigurator = $('.product--configurator--item--inner', $configurator);
            if ($itemConfigurator.length) {
                $itemConfigurator.on('click', function () {
                    let $open = false;
                    const $attributesItemConfigurator = $(this).closest('.product--configurator--item').find('.product--configurator--attributes');
                    if (!$attributesItemConfigurator.hasClass('product--configurator--attributes--open')) {
                        $open = true;
                    }
                    $body.removeClass('body--product--configurator--open');
                    $('.product--configurator--attributes').removeClass('product--configurator--attributes--open');
                    $('.product--configurator--item--action').removeClass('product--configurator--item--action--open');
                    if ($open) {
                        $body.addClass('body--product--configurator--open');
                        $attributesItemConfigurator.addClass('product--configurator--attributes--open');
                        $('.product--configurator--item--action', $(this)).addClass('product--configurator--item--action--open');
                    }
                });
                const $itemMultiConfigurator = $('.product--configurator--attributes--multi', $configurator);
                if ($itemMultiConfigurator.length) {
                    const $attributeItemConfigurator = $('.product--configurator--attributes--item', $itemMultiConfigurator);
                    if ($attributeItemConfigurator.length) {
                        // // mise en place du slider pour la version mobile
                        // if (window.matchMedia("(max-width: 1023px)").matches) {
                        //     $attributeItemConfigurator.each(function () {
                        //         $(this).slick({
                        //             appendArrows: '.product--configurator--attributes--item--arrows',
                        //             arrows: true,
                        //             centerMode: false,
                        //             centerPadding: '0',
                        //             cssEase: 'ease-in-out',
                        //             dots: true,
                        //             dotsClass: 'product--configurator--attributes--item--dots',
                        //             edgeFriction: 0,
                        //             fade: true,
                        //             infinite: true,
                        //             nextArrow: '<div class="product--images--arrow product--images--arrow--next">' + $arrowNav + '</div>',
                        //             prevArrow: '<div class="product--images--arrow product--images--arrow--prev">' + $arrowNav + '</div>',
                        //             slidesToScroll: 1,
                        //             slidesToShow: 1
                        //         });
                        //     });
                        // }
                        const $classTarget = ['attribute_select', 'attribute_select_option', 'attribute_radio', 'color_pick', 'select_product'];
                        $attributeItemConfigurator.on('click', function (e) {
                            let $execute = true;
                            // on vérifie sur quel élément on a cliqué pour éviter de refermer la div sans que le choix de l'option ait été validé (à cause des clics imbriqués)
                            let $classList = e.target.classList;
                            if ($classList.length) {
                                for (let i in $classList) {
                                    if ($.inArray($classList[i], $classTarget) != -1) {
                                        $execute = false;
                                    }
                                }
                            }
                            if ($execute) {
                                $attributeItemConfigurator.removeClass('configured');
                                $('.product--configurator--attributes--combinations', $attributeItemConfigurator).removeClass('product--configurator--attributes--combinations--open');
                                const $height = $(this).innerHeight();
                                const $offsetParent = $(this).closest('.product--configurator--attributes--multi').offset().top;
                                const $offsetTop = $(this).offset().top;
                                const $top = $height + ($offsetTop - $offsetParent) + 5;
                                if (!$('.product--configurator--attributes--combinations', $(this)).hasClass('product--configurator--attributes--combinations--open')) {
                                    $(this).addClass('configured');
                                    $('.product--configurator--attributes--combinations', $(this)).css({
                                        top: $top,
                                    });
                                    $('.product--configurator--attributes--combinations', $(this)).addClass('product--configurator--attributes--combinations--open');
                                }
                            }
                        });
                    }
                }
            }
        }
    }

    const $accountForm = $('.account--form--content');
    if ($accountForm.length) {
        $('input[type="file"]').change(function(e) {
            let $name = e.target.files[0].name;
            $(this).closest('.account--form--row').find('span.filename').text('(' + $name + ')');
        });
    }

    const $hookPayment = $('#HOOK_PAYMENT');
    if ($hookPayment.length) {
        $('#HOOK_PAYMENT .paiement_cb').appendTo($hookPayment);
    }

    const $favoritesCarts = $('.page--account--favorites-carts');
    if ($favoritesCarts.length) {
        const $historyDetail = $('.history_detail');
        $historyDetail.on('click', function() {
            let $var_content = $(this).find('div').attr('data-id_favorite_cart');
            let $file = $(this).find('div').attr('data-link');
            showFavoriteCart($var_content, $file)
        })

        const $historyDelete = $('.history_delete');
        $historyDelete.on('click', function() {
            let $var_content = $(this).find('div').attr('data-id_favorite_cart');
            let $file = $(this).find('div').attr('data-link');
            deleteFavoriteCart($var_content, $file)
        })
    }

    function showFavoriteCart(var_content, file) {
        $.get(file, {
            'id_favorite_cart': var_content,
            'detailFavoriteCart': true,
            'ajax': true
        }, function(data) {
            $('#block-order-detail').fadeOut('slow', function() {
                $(this).html(data).fadeIn('slow', function() {
                    // $.scrollTo(this, 200);
                });
            });
        });
    }

    function deleteFavoriteCart(var_content, file) {
        $.get(file, {
            'id_favorite_cart': var_content,
            'deleteFavoriteCart': true,
            'ajax': true
        }, function(data) {
            if (data == '1') {
                $('#idFavoriteCart'+var_content).remove();
                $('#block-order-detail').html('');
            }
        });
    }

    // Fancybox
    if ($('.fancybox').length) {
        $('a.fancybox').fancybox();
    }

    $('.span-fancybox').fancybox({
        helpers: {
            overlay: {
                css: {
                    'background': 'rgba(0, 0, 0, 0.8)'
                }
            }
        },
        beforeShow: function () {
            let src = this.element.find('img').parent().attr('data-href');
            if ($(window).outerWidth() < $(window).outerHeight()) {
                this.content.find('img').attr({
                    src: src,
                    width: '1920',
                    height: '1080',
                    style: 'width:' + ($(window).width() - 105) + 'px; height:auto'
                });
            } else {
                this.content.find('img').attr({
                    src: src,
                    width: '1920',
                    height: '1080',
                    style: 'width:auto;height:' + ($(window).height() - 105) + 'px'
                });
            }
        }
    });

    const $fancybox = $('.div-fancybox');
    if ($fancybox.length) {
        $fancybox.on('click', function () {
            let target = $(this).attr('data-href');
            if ($('#thumbs').length) {
                $('#thumbs span[data-href="' + target + '"]').trigger('click');
            } else {
                $.fancybox({
                    href: $(this).attr('data-href')
                });
            }
        });
    }

    $(document).ready(function() {
        if ($('.g-recaptcha').length) {
            $('.g-recaptcha').each(function() {
                $('<div class="tacReCAPTCHA"></div>').insertBefore($(this));
            });
        };
    });

    function scrollTo($element, $target, $delta, $way, $speed) {
        $element.on('click', function (e) {
            let $offsetTop = 0;
            if ($way === '-') {
                $offsetTop = $target.offset().top - $delta;
            } else {
                $offsetTop = $target.offset().top + $delta;
            }
            $htmlBody.animate({
                scrollTop: $offsetTop
            }, $speed);
            e.preventDefault();
        });
    }

    $('button[data-action="show-password"]').on('click', function () {
        var elm = $(this).closest('.form-group').children('input.js-visible-password');
        if (elm.attr('type') === 'password') {
            elm.attr('type', 'text');
        } else {
            elm.attr('type', 'password');
        }
    });
});